import ReactDOM from 'react-dom'
import { useBodyScrollLock } from '/machinery/useBodyScrollLock'
import { usePortalNode } from '/machinery/usePortalNode'
import { Icon } from '/features/buildingBlocks/Icon'

import cross from '/images/icons/cross.raw.svg'
import styles from './Modal.css'

export function Modal({ visible, children, onClose }) {
  const portalNode = usePortalNode(styles._rootComponent)
  const { ref: bodyScrollLockRef } = useBodyScrollLock({ enabled: visible })

  useEscape(onClose)

  return portalNode && ReactDOM.createPortal(
    <div className={cx(styles.component, visible && styles.visible)} ref={bodyScrollLockRef} aria-hidden={!visible}>
      <div className={styles.close}><CloseButton onClick={onClose} /></div>
      <div className={styles.inner}>
        {children}
      </div>
      <button
        type='button'
        onClick={onClose}
        className={styles.backdrop}
        data-x='close-modal'
        aria-label='Close modal'
      />
    </div>,
    portalNode
  )
}

function CloseButton({ onClick }) {
  return (
    <button
      className={styles.componentCloseButton}
      type='button'
      data-x='close-modal'
      aria-label='Close modal'
      {... { onClick }}
    >
      <Icon icon={cross} />
    </button>
  )
}

function useEscape(callback) {
  const callbackRef = React.useRef(null)
  callbackRef.current = callback

  React.useEffect(
    () => {
      window.addEventListener('keydown', handleKeyDown)
      return () => window.removeEventListener('keydown', handleKeyDown)

      function handleKeyDown(e) {
        if (['Esc', 'Escape'].includes(e.key)) {
          callbackRef.current()
        }
      }
    },
    []
  )
}
