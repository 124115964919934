export function useFullscreenHandling() {
  const [fullscreenVideoReady, setFullScreenVideoReady] = React.useState(false)

  const [fullscreenVideoActive, setFullscreenVideoActive] = React.useState(false)

  return {
    fullscreenVideoReady,
    setFullScreenVideoReady,
    fullscreenVideoActive,
    handleFullscreenVideo,
    handleCloseFullscreenVideo,
  }

  function handleFullscreenVideo() {
    setFullscreenVideoActive(true)
  }

  function handleCloseFullscreenVideo() {
    setFullscreenVideoActive(false)
  }
}
