import { useReportError } from '/machinery/ReportError'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import ReactPlayer from 'react-player/lazy'
import { useSpring, animated } from '@react-spring/web'
import { useVideoTracking } from '/machinery/tracking/useVideoTracking'
import { ImageCover } from '/features/buildingBlocks/Image'
import { CtaButtonPrimary } from '/features/buildingBlocks/CtaButton'

import iconPlay from '/images/icons/play.raw.svg'

import styles from './VideoPlayer.css'

const playerConfig = {
  youtube: {
    embedOptions: {
      host: 'https://www.youtube-nocookie.com',
    },
    playerVars: {
      modestbranding: 1,
      playsinline: 1,
    },
  },
  vimeo: {
    playerOptions: {
      controls: true,
      playsinline: true,
      title: false,
    },
  },
}

const INITIAL = 'initial'
const PLAYING = 'playing'
const ENDED = 'ended'

export function VideoPlayer({ url, image = undefined, videoTitle, buttonText = undefined, playing = false, onReady = undefined, hideButton = false }) {
  const isMounted = useRenderOnMount()
  const reportError = useReportError()
  const [playerState, setPlayerState] = React.useState(INITIAL)
  const showPoster = [INITIAL, ENDED].includes(playerState)

  const animatedPosterProps = useSpring({ opacity: showPoster ? 1 : 0 })

  const shouldShowPlayButton = !hideButton && showPoster

  const {
    eventHandlers: {
      onEnded: onEndedTracking,
      ...eventHandlers
    }
  } = useVideoTracking({ title: videoTitle })

  return (
    <animated.div className={styles.component}>
      {isMounted ? <ReactPlayer
        // eslint-disable-next-line @kaliber/layout-class-name
        className={styles.video}
        onPlay={() => { setPlayerState(PLAYING) }}
        onEnded={e => {
          onEndedTracking(e)
          setPlayerState(ENDED)
        }}
        onReady={e => { onReady && onReady(e) }}
        onError={reportError}
        controls
        config={playerConfig}
        playing={playerState === PLAYING}
        {...{ url, playing, ...eventHandlers }}
      /> : null }
      <div
        className={styles.poster}
        style={{ pointerEvents: showPoster ? 'auto' : 'none' }}
      >
        <animated.div
          className={styles.posterImage}
          style={animatedPosterProps}
        >
          {image && <ImageCover
            aspectRatio={3 / 2}
            {...{ image }}
          />}
        </animated.div>

        <PlayButton
          visible={shouldShowPlayButton}
          onClick={() => {
            setPlayerState(PLAYING)
          }}
          {...{ buttonText }}
        />
      </div>

    </animated.div>
  )
}

function PlayButton({ visible, buttonText, onClick }) {
  const animatedButtonProps = useSpring({ opacity: visible ? 1 : 0 })

  return (
    <animated.div style={{ opacity: animatedButtonProps.opacity }}>
      <CtaButtonPrimary dataX='click-to-play' {...{ onClick }} icon={iconPlay}>
        {buttonText}
      </CtaButtonPrimary>
    </animated.div>
  )
}
