import { VideoPlayer } from '/features/buildingBlocks/VideoPlayer'
import { BackgroundVideo } from '/features/buildingBlocks/BackgroundVideo'
import { CtaButtonPrimary } from '/features/buildingBlocks/CtaButton'
import { Modal } from '/features/buildingBlocks/Modal'
import { useFullscreenHandling } from '/machinery/useFullscreenHandling'

import playIcon from '/images/icons/play.raw.svg'
import styles from './Video.css'

export function Video({ videoUrls, videoUrl, videoTitle, buttonText }) {
  const previewSources = videoUrls && {
    1080: videoUrls.quality1080,
    720: videoUrls.quality720,
    540: videoUrls.quality540,
    360: videoUrls.quality360,
  }

  const {
    fullscreenVideoReady,
    setFullScreenVideoReady,
    fullscreenVideoActive,
    handleFullscreenVideo,
    handleCloseFullscreenVideo,
  } = useFullscreenHandling()

  return (
    <>
      {fullscreenVideoReady &&
        <div className={styles.backgroundVideoContainer}>
          <CtaButtonPrimary layoutClassName={styles.videoButton} dataX='play-video' onClick={handleFullscreenVideo} icon={playIcon}>
            {buttonText}
          </CtaButtonPrimary>
          {previewSources && <BackgroundVideo
            layoutClassName={styles.backgroundVideo}
            sources={previewSources}
            playing={!fullscreenVideoActive}
          />}
        </div>
      }

      <div className={cx(styles.videoFull, fullscreenVideoActive && styles.isActive)}>
        <Modal onClose={handleCloseFullscreenVideo} visible={fullscreenVideoActive}>
          <VideoPlayer
            hideButton
            playing={fullscreenVideoActive}
            url={videoUrl}
            onReady={_ => setFullScreenVideoReady(true)}
            {...{ videoTitle, buttonText }}
          />
        </Modal>
      </div>
    </>
  )
}
